//const serverIp = 'https://api.hbbakery.ru/'
// const serverIp = 'http://127.0.0.1:5500/';
// const serverIp = 'http://localhost:5500/';
//Elita
// const serverIp = 'https://api.12.hungrygator.ru/'
//Pirogi
const serverIp = 'https://api.01.hungrygator.ru/';
//Zakazplova
// const serverIp = 'https://api.11.hungrygator.ru/';
//Slawa

// const serverIp = 'https://api.13.hungrygator.ru/';

//2221113321

//Vibes
//const serverIp = 'https://api.14.hungrygator.ru/';
//Sumo
//const serverIp = 'https://api.15.hungrygator.ru/';

//Fresh
// const serverIp = 'https://api.17.hungrygator.ru/';

//Pirogovich
// const serverIp = 'https://api.17.hungrygator.ru/';

export default serverIp;

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const CLEAR_CURRENT_USER = "CLEAR_CURRENT_USER";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

//IIKO
export const GET_IIKO_SETTINGS = "GET_IIKO_SETTINGS";
export const EDIT_IIKO_SETTINGS = "EDIT_IIKO_SETTINGS";
export const SAVE_IIKO_SETTINGS = "SAVE_IIKO_SETTINGS";
export const LOADING_IIKO_SETTINGS = "LOADING_IIKO_SETTINGS";
export const IIKO_AUTH_CHECK = "IIKO_AUTH_CHECK";
export const IIKO_MENU_LOADED = "IIKO_MENU_LOADED";
export const IIKO_MENU_LOADING = "IIKO_MENU_LOADING";

//SUMOIIKO
export const GET_SUMO_IIKO_SETTINGS = "GET_SUMO_IIKO_SETTINGS";
export const LOADING_SUMO_IIKO_SETTINGS = "LOADING_SUMO_IIKO_SETTINGS";
export const SUMO_IIKO_CHECK = "SUMO_IIKO_CHECK";
export const SUMO_IIKO_MENU_LOADED = "SUMO_IIKO_MENU_LOADED";
export const SUMO_IIKO_MENU_LOADING = "SUMO_IIKO_MENU_LOADING";

//Список товаров
export const GET_ITEM_LIST_SORTED = "GET_ITEM_LIST_SORTED";
export const ITEM_TABLE_LOADING = "ITEM_TABLE_LOADING";

export const SHOW_OP_ITEM_MODAL = "SHOW_OP_ITEM_MODAL";
export const CLOSE_OP_ITEM_MODAL = "CLOSE_OP_ITEM_MODAL";
export const GET_ITEM_LIST = "GET_ITEM_LIST";
export const GET_ITEM_ERRORS = "GET_ITEM_ERRORS";
export const LOADING_ITEMS = "LOADING_ITEMS";
export const ITEM_ADDED = "ITEM_ADDED";
export const SHOW_ADD_ITEM_MODAL = "SHOW_ADD_ITEM_MODAL";
export const CLOSE_ADD_ITEM_MODAL = "CLOSE_ADD_ITEM_MODAL";
export const SHOW_DEL_ITEM_MODAL = "SHOW_DEL_ITEM_MODAL";
export const CLOSE_DEL_ITEM_MODAL = "CLOSE_DEL_ITEM_MODAL";
export const ITEM_DELETED = "ITEM_DELETED";
export const SHOW_ED_ITEM_MODAL = "SHOW_ED_ITEM_MODAL";
export const CLOSE_ED_ITEM_MODAL = "CLOSE_ED_ITEM_MODAL";
export const ITEM_EDITED = "ITEM_EDITED";
export const DELETING_ITEM = "DELETING_ITEM";
export const ADDING_ITEM = "ADDING_ITEM";
export const EDITING_ITEM = "EDITING_ITEM";
export const SHOW_ADD_IMG_MODAL = "SHOW_ADD_IMG_MODAL";
export const CLOSE_ADD_IMG_MODAL = "CLOSE_ADD_IMG_MODAL";
export const ITEM_IMG_ADDED = "ITEM_IMG_ADDED";
export const ITEM_IMG_DELETED = "ITEM_IMG_DELETED";
export const IMG_BTN_DISABLE = "IMG_BTN_DISABLE";
export const GET_ITEM_OPTION_LIST = "GET_ITEM_OPTION_LIST";
export const ITEM_OPTION_LIST_LOADING = "ITEM_OPTION_LIST_LOADING";
export const CLOSE_OP_ITEM_MODAL_DEL = "CLOSE_OP_ITEM_MODAL_DEL";
export const SHOW_OP_ITEM_MODAL_DEL = "SHOW_OP_ITEM_MODAL_DEL";
export const ITEM_OPTION_ADDED = "ITEM_OPTION_ADDED";
export const ITEM_OPTION_DELETED = "ITEM_OPTION_DELETED";
export const OPTION_ADDING = "OPTION_ADDING";
export const OPTION_DELETING = "OPTION_DELETING";
export const GET_ITEM_CHECKBOX_OPTION_LIST = "GET_ITEM_CHECKBOX_OPTION_LIST";
export const ITEM_CHECKBOX_OPTION_LIST_LOADING = "ITEM_CHECKBOX_OPTION_LIST_LOADING";
export const SHOW_OP_CHECKBOX_ITEM_MODAL = "SHOW_OP_CHECKBOX_ITEM_MODAL";
export const CLOSE_OP_CHECKBOX_ITEM_MODAL = "CLOSE_OP_CHECKBOX_ITEM_MODAL";
export const SHOW_EDIT_FILTERITEM_MODAL = "SHOW_ADD_FILTERITEM_MODAL";
export const CLOSE_EDIT_FILTERITEM_MODAL = "CLOSE_ADD_FILTERITEM_MODAL";
export const EDITED_FILTERITEM = "EDITED_FILTERITEM";

export const GET_ITEM_RADIO_OPTION_LIST = "GET_ITEM_RADIO_OPTION_LIST";
export const ITEM_RADIO_OPTION_LIST_LOADING = "ITEM_RADIO_OPTION_LIST_LOADING";
export const CLOSE_OP_RADIO_ITEM_MODAL = "CLOSE_OP_RADIO_ITEM_MODAL";
export const SHOW_OP_RADIO_ITEM_MODAL = "SHOW_OP_RADIO_ITEM_MODAL";
export const ITEM_RADIO_ADDED = "ITEM_RADIO_ADDED";
export const ITEM_RADIO_DELETED = "ITEM_RADIO_DELETED";

//Отчет
export const REPORT_BASE_LOADED = "REPORT_BASE_LOADED";
export const REPORT_BASE_LOADING = "REPORT_BASE_LOADING";
export const REPORT_ITEMS_LOADED = "REPORT_ITEMS_LOADED";
export const REPORT_ITEMS_LOADING = "REPORT_ITEMS_LOADING";
export const REPORT_GET_ERRORS = "REPORT_GET_ERRORS";

//Опции товаров
export const LOADING_OPTIONS = "LOADING_OPTIONS";
export const SHOW_ADD_OPTION_MODAL = "SHOW_ADD_OPTION_MODAL";
export const CLOSE_ADD_OPTION_MODAL = "CLOSE_ADD_OPTION_MODAL";
export const GET_OPTION_LIST = "GET_OPTION_LIST";
export const GET_OPTION_ERRORS = "GET_OPTION_ERRORS";
export const ADDING_OPTIONS = "ADDING_OPTIONS";
export const OPTION_ADDED = "OPTION_ADDED";
export const OPTION_DELETED = "OPTION_DELETED";
export const DELETING_OPTION = "DELETING_OPTION";
export const SHOW_DEL_OPTION_MODAL = "SHOW_DEL_OPTION_MODAL";
export const CLOSE_DEL_OPTION_MODAL = "CLOSE_DEL_OPTION_MODAL";
export const SHOW_ADD_OPTION_CHECKBOX_MODAL = "SHOW_ADD_OPTION_CHECKBOX_MODAL";
export const CLOSE_ADD_OPTION_CHECKBOX_MODAL = "CLOSE_ADD_OPTION_CHECKBOX_MODAL";
export const ADDING_CHECKBOX_OPTIONS = "ADDING_CHECKBOX_OPTIONS";
export const CHECKBOX_OPTION_ADDED = "CHECKBOX_OPTION_ADDED";
export const CHECKBOX_OPTION_DELETED = "CHECKBOX_OPTION_DELETED";
export const SHOW_DEL_CHECKBOX_OPTION_MODAL = "SHOW_DEL_CHECKBOX_OPTION_MODAL";
export const CLOSE_DEL_CHECKBOX_OPTION_MODAL = "CLOSE_DEL_CHECKBOX_OPTION_MODAL";

export const CHECKBOX_OPTION_DELETING = "CHECKBOX_OPTION_DELETING";
export const CHECKBOX_OPTION_DELETING_CONFIRM = "CHECKBOX_OPTION_DELETING_CONFIRM";
export const SHOW_ADD_OPTION_RADIO_MODAL = "SHOW_ADD_OPTION_RADIO_MODAL";
export const CLOSE_ADD_OPTION_RADIO_MODAL = "CLOSE_ADD_OPTION_RADIO_MODAL";
export const RADIO_OPTION_ADDED = "RADIO_OPTION_ADDED";
export const ADDING_RADIO_OPTIONS = "ADDING_RADIO_OPTIONS";
export const RADIO_OPTION_DELETED = "RADIO_OPTION_DELETED";

//Покупатели
export const ALL_CUSTOMERS_LOADED = "ALL_CUSTOMERS_LOADED";
export const ALL_CUSTOMERS_LOADING = "ALL_CUSTOMERS_LOADING";

export const GET_CUSTOMERS_LIST = "GET_CUSTOMERS_LIST";
export const LOADING_CUSTOMERS = "LOADING_CUSTOMERS";
export const GET_CUSTOMERS_ERRORS = "GET_CUSTOMERS_ERRORS";
export const SHOW_ADD_CUSTOMER_MODAL = "SHOW_ADD_CUSTOMER_MODAL";
export const CLOSE_ADD_CUSTOMER_MODAL = "CLOSE_ADD_CUSTOMER_MODAL";
export const CUSTOMER_ADDED = "CUSTOMER_ADDED";
export const ADDING_CUSTOMER = "ADDING_CUSTOMER";
export const SHOW_DEL_CUSTOMER_MODAL = "SHOW_DEL_CUSTOMER_MODAL";
export const CLOSE_DEL_CUSTOMER_MODAL = "CLOSE_DEL_CUSTOMER_MODAL";
export const CUSTOMER_DELETED = "CUSTOMER_DELETED";
export const LOADING_CUSTOMERS_PAGE = "LOADING_CUSTOMERS_PAGE";
export const SHOW_ORDER_HISTORY_CUSTOMER_MODAL = "SHOW_ORDER_HISTORY_CUSTOMER_MODAL";
export const CLOSE_ORDER_HISTORY_CUSTOMER_MODAL = "CLOSE_ORDER_HISTORY_CUSTOMER_MODAL";
export const SHOW_BONUS_HISTORY_CUSTOMER_MODAL = "SHOW_BONUS_HISTORY_CUSTOMER_MODAL";
export const CLOSE_BONUS_HISTORY_CUSTOMER_MODAL = "CLOSE_BONUS_HISTORY_CUSTOMER_MODAL";
export const ORDER_HISTORY_CUSTOMER_LOADED = "ORDER_HISTORY_CUSTOMER_LOADED";
export const ORDER_HISTORY_CUSTOMER_LOADING = "ORDER_HISTORY_CUSTOMER_LOADING";
export const SHOW_ADD_BONUS_CUSTOMER_MODAL = "SHOW_ADD_BONUS_CUSTOMER_MODAL";
export const CLOSE_ADD_BONUS_CUSTOMER_MODAL = "CLOSE_ADD_BONUS_CUSTOMER_MODAL";
export const SHOW_DEL_BONUS_CUSTOMER_MODAL = "SHOW_DEL_BONUS_CUSTOMER_MODAL";
export const CLOSE_DEL_BONUS_CUSTOMER_MODAL = "CLOSE_DEL_BONUS_CUSTOMER_MODAL";
export const CUSTOMER_BONUS_ADDED = "CUSTOMER_BONUS_ADDED";
export const CUSTOMER_BONUS_DELETED = "CUSTOMER_BONUS_DELETED";
export const SHOW_EDIT_CUSTOMER_MODAL = "SHOW_EDIT_CUSTOMER_MODAL";
export const CLOSE_EDIT_CUSTOMER_MODAL = "CLOSE_EDIT_CUSTOMER_MODAL";
export const CUSTOMER_EDITING = "CUSTOMER_EDITING";
export const CUSTOMER_EDITED = "CUSTOMER_EDITED";

//Настройки приложения
export const GET_SETTINGS = "GET_SETTINGS";
export const LOADING_SETTINGS = "LOADING_SETTINGS";
export const GET_SETTINGS_ERRORS = "GET_SETTINGS_ERRORS";
export const EDIT_SETTINGS = "EDIT_SETTINGS";
export const SAVED_SETTINGS = "SAVED_SETTINGS";
export const SAVED_SETTINGS_ERRORS = "SAVED_SETTINGS_ERRORS";

//Настройки интеграций
export const GET_INTEGRATIONS = "GET_INTEGRATIONS";
export const LOADING_INTEGRATION_SETTINGS = "LOADING_INTEGRATION_SETTINGS";
export const GET_INTEGRATION_SETTINGS_ERRORS = "GET_INTEGRATION_SETTINGS_ERRORS";
export const EDIT_INTEGRATION_SETTINGS = "EDIT_INTEGRATION_SETTINGS";
export const SAVED_INTEGRATION_SETTINGS = "SAVED_INTEGRATION_SETTINGS";
export const SAVED_INTEGRATION_SETTINGS_ERRORS = "SAVED_INTEGRATION_SETTINGS_ERRORS";
// ----- ArchiDelivery ----- //
export const EDIT_ARCHI_INTEGRATION = "EDIT_ARCHI_INTEGRATION";
export const SAVE_ARCHI_INTEGRATION = "SAVE_ARCHI_INTEGRATION";
// ----- Delivery Club ----- //
export const EDIT_DELIVERYCLUB_INTEGRATION = "EDIT_DELIVERYCLUB_INTEGRATION";
export const SAVE_DELIVERYCLUB_INTEGRATION = "SAVE_DELIVERYCLUB_INTEGRATION";

//Отзывы
export const GET_REVIEW_LIST = "GET_REVIEW_LIST";
export const LOADING_REVIEW = "LOADING_REVIEW";
export const GET_REVIEW_ERRORS = "GET_REVIEW_ERRORS";
export const REVIEW_EDITING = "REVIEW_EDITING";
export const SHOW_ADD_REVIEW_MODAL = "SHOW_ADD_REVIEW_MODAL";
export const CLOSE_ADD_REVIEW_MODAL = "CLOSE_ADD_REVIEW_MODAL";
export const SHOW_EDIT_REVIEW_MODAL = "SHOW_EDIT_REVIEW_MODAL";
export const CLOSE_EDIT_REVIEW_MODAL = "CLOSE_EDIT_REVIEW_MODAL";
export const REVIEW_EDITED = "REVIEW_EDITED";
export const REVIEW_ADDED = "REVIEW_ADDED";
export const REVIEW_ADDING = "REVIEW_ADDING";
export const REVIEW_DELETED = "REVIEW_DELETED";

//Категории
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const LOADING_CATEGORY = "LOADING_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const GET_CATEGORY_ERRORS = "GET_CATEGORY_ERRORS";
export const CATEGORY_ADDED = "CATEGORY_ADDED";
export const SHOW_ADD_CATEGORY_MODAL = "SHOW_ADD_CATEGORY_MODAL";
export const CLOSE_ADD_CATEGORY_MODAL = "CLOSE_ADD_CATEGORY_MODAL";
export const SHOW_DEL_CATEGORY_MODAL = "SHOW_DEL_CATEGORY_MODAL";
export const CLOSE_DEL_CATEGORY_MODAL = "CLOSE_DEL_CATEGORY_MODAL";
export const CATEGORY_DELETED = "CATEGORY_DELETED";
export const SHOW_ED_CATEGORY_MODAL = "SHOW_ED_CATEGORY_MODAL";
export const CLOSE_ED_CATEGORY_MODAL = "CLOSE_ED_CATEGORY_MODAL";
export const CATEGORY_EDITED = "CATEGORY_EDITED";
export const SHOW_CATEGORY_IMG = "SHOW_CATEGORY_IMG";
export const CLOSE_CATEGORY_IMG = "CLOSE_CATEGORY_IMG";
export const CATEGORY_IMG_ADDING = "CATEGORY_IMG_ADDING";
export const CATEGORY_IMG_ADDED = "CATEGORY_IMG_ADDED";
export const CATEGORY_IMG_DELETED = "CATEGORY_IMG_DELETED";

//Доставка
export const GET_DELIVERY_LIST = "GET_DELIVERY_LIST";
export const LOADING_DELIVERY = "LOADING_DELIVERY";
export const GET_DELIVERY_ERRORS = "GET_DELIVERY_ERRORS";
export const DELIVERY_EDITING = "DELIVERY_EDITING";
export const SHOW_ADD_DELIVERY_MODAL = "SHOW_ADD_DELIVERY_MODAL";
export const CLOSE_ADD_DELIVERY_MODAL = "CLOSE_ADD_DELIVERY_MODAL";
export const DELIVERY_ADDED = "DELIVERY_ADDED";
export const DELIVERY_ADDING = "DELIVERY_ADDING";
export const DELIVERY_DELETED = "DELIVERY_DELETED";
export const SHOW_EDIT_DELIVERY_MODAL = "SHOW_EDIT_DELIVERY_MODAL";
export const CLOSE_EDIT_DELIVERY_MODAL = "CLOSE_EDIT_DELIVERY_MODAL";
export const DELIVERY_EDITED = "DELIVERY_EDITED";

//Оплата
export const GET_PAYMENT_LIST = "GET_PAYMENT_LIST";
export const LOADING_PAYMENT = "LOADING_PAYMENT";
export const GET_PAYMENT_ERRORS = "GET_PAYMENT_ERRORS";
export const PAYMENT_EDITING = "PAYMENT_EDITING";
export const SHOW_ADD_PAYMENT_MODAL = "SHOW_ADD_PAYMENT_MODAL";
export const CLOSE_ADD_PAYMENT_MODAL = "CLOSE_ADD_PAYMENT_MODAL";
export const SHOW_EDIT_PAYMENT_MODAL = "SHOW_EDIT_PAYMENT_MODAL";
export const CLOSE_EDIT_PAYMENT_MODAL = "CLOSE_EDIT_PAYMENT_MODAL";
export const PAYMENT_ADDED = "PAYMENT_ADDED";
export const PAYMENT_ADDING = "PAYMENT_ADDING";
export const PAYMENT_EDITED = "PAYMENT_EDITED";
export const PAYMENT_DELETED = "PAYMENT_DELETED";

//Заказы
export const LOADING_CUSTOMER_BONUS = "LOADING_CUSTOMER_BONUS";
export const LOADED_CUSTOMER_BONUS = "LOADED_CUSTOMER_BONUS";

export const ORDER_CHECK_PAYMENT_ANSWER = "ORDER_CHECK_PAYMENT_ANSWER";
export const NEW_ORDER_CREATING = "NEW_ORDER_CREATING";
export const NEW_ORDER_CREATED = "NEW_ORDER_CREATED";
export const ORDER_ITEMS_WITH_CATEGORIES_TO_ADD_LOADED = "ORDER_ITEMS_WITH_CATEGORIES_TO_ADD_LOADED";
export const GET_ORDER_LIST = "GET_ORDER_LIST";
export const LOADING_ORDERS = "LOADING_ORDERS";
export const GET_ORDERS_ERRORS = "GET_ORDERS_ERRORS";
export const SHOW_CONTENT_MODAL = "SHOW_CONTENT_MODAL";
export const CLOSE_CONTENT_MODAL = "CLOSE_CONTENT_MODAL";
export const GET_ORDER_LIST_UPDATE = "GET_ORDER_LIST_UPDATE";
export const SHOW_ORDER_STATUS_MODAL = "SHOW_ORDER_STATUS_MODAL";
export const CLOSE_ORDER_STATUS_MODAL = "CLOSE_ORDER_STATUS_MODAL";
export const ORDER_STATUS_UPDATE = "ORDER_STATUS_UPDATE";
export const ORDER_ITEM_DELETED = "ORDER_ITEM_DELETED";
export const SHOW_ADD_TO_ORDER_MODAL = "SHOW_ADD_TO_ORDER_MODAL";
export const CLOSE_ADD_TO_ORDER_MODAL = "CLOSE_ADD_TO_ORDER_MODAL";
export const ORDER_ITEMS_TO_ADD_LOADING = "ORDER_ITEMS_TO_ADD_LOADING";
export const ORDER_ITEMS_TO_ADD_LOADED = "ORDER_ITEMS_TO_ADD_LOADED";
export const ORDER_ADD_ITEM = "ORDER_ADD_ITEM";
export const ORDER_ADD_ITEM_ADDING = "ORDER_ADD_ITEM_ADDING";
export const ORDER_OPEN_CHEQUE_MODAL = "ORDER_OPEN_CHEQUE_MODAL";
export const ORDER_CLOSE_CHEQUE_MODAL = "ORDER_CLOSE_CHEQUE_MODAL";
export const SHOW_ADD_COURIER_ORDER = "SHOW_ADD_COURIER_ORDER";
export const CLOSE_ADD_COURIER_ORDER = "CLOSE_ADD_COURIER_ORDER";
export const ORDER_COURIER_LIST_LOADED = "ORDER_COURIER_LIST_LOADED";
export const ORDER_COURIER_ADDED = "ORDER_COURIER_ADDED";
export const ORDER_COURIER_ADDING = "ORDER_COURIER_ADDING";
export const LOADING_ORDER_PAGE = "LOADING_ORDER_PAGE";
export const NEW_ORDER_NEED_TO_BE_LOAD = "NEW_ORDER_NEED_TO_BE_LOAD";
export const GET_NEW_ORDER = "GET_NEW_ORDER";
export const GET_FILTERED_ORDERS = "GET_FILTERED_ORDERS";
export const SHOW_ORDER_CREATE_MODAL = "SHOW_ORDER_CREATE_MODAL";
export const HIDE_ORDER_CREATE_MODAL = "HIDE_ORDER_CREATE_MODAL";
export const GET_SEARCHED_CLIENTS = "GET_SEARCHED_CLIENTS";
export const ORDER_RESET_CLIENT_SEARCH = "ORDER_RESET_CLIENT_SEARCH";
export const GET_CREATED_ORDER = "GET_CREATED_ORDER";
export const UPDATE_ORDER_DELIVERED = "UPDATE_ORDER_DELIVERED";
export const OPEN_ORDER_CALC_CHANGE = "OPEN_ORDER_CALC_CHANGE";
export const CLOSE_ORDER_CALC_CHANGE = "CLOSE_ORDER_CALC_CHANGE";
export const ORDER_CALC_CHANGED = "ORDER_CALC_CHANGED";
export const OPEN_ORDER_EDIT_COMMENT = "OPEN_ORDER_EDIT_COMMENT";
export const CLOSE_ORDER_EDIT_COMMENT = "CLOSE_ORDER_EDIT_COMMENT";
export const ORDER_EDITED_COMMENT = "ORDER_EDITED_COMMENT";
export const ORDER_SMALL_REPORT_RECIEVED = "ORDER_SMALL_REPORT_RECIEVED";
export const ORDER_SMALL_REPORT_LOADING = "ORDER_SMALL_REPORT_LOADING";
export const ORDER_UPDATED = "ORDER_UPDATED";
export const ORDER_OPEN_ADDRESS_MODAL = "ORDER_OPEN_ADDRESS_MODAL";
export const ORDER_CLOSE_ADDRESS_MODAL = "ORDER_CLOSE_ADDRESS_MODAL";
export const ORDER_SET_NEW_ADDRESS = "ORDER_SET_NEW_ADDRESS";
export const ORDER_NEW_ADDRESS_EDITING = "ORDER_NEW_ADDRESS_EDITING";
export const OPEN_ORDER_PRINT_FILTERED = "OPEN_ORDER_PRINT_FILTERED";
export const CLOSE_ORDER_PRINT_FILTERED = "CLOSE_ORDER_PRINT_FILTERED";
export const ORDER_GIFT_ITEMS_TO_ADD_LOADED = "ORDER_GIFT_ITEMS_TO_ADD_LOADED";

//Настройки
export const EDIT_API_SETTINGS_ORDER_STATUS = "EDIT_API_SETTINGS_ORDER_STATUS";
export const GET_API_SETTINGS = "GET_API_SETTINGS";
export const LOADING_API_SETTINGS = "LOADING_API_SETTINGS";
export const GET_API_SETTINGS_ERRORS = "GET_API_SETTINGS_ERRORS";
export const EDIT_API_SETTINGS = "EDIT_API_SETTINGS";
export const SAVED_API_SETTINGS = "SAVED_API_SETTINGS";
export const SAVED_API_SETTINGS_ERRORS = "SAVED_API_SETTINGS_ERRORS";
export const SHOW_ADD_PROMO_MODAL = "SHOW_ADD_PROMO_MODAL";
export const CLOSE_ADD_PROMO_MODAL = "CLOSE_ADD_PROMO_MODAL";
export const PROMO_ADDING = "PROMO_ADDING";
export const PROMO_ADDED = "PROMO_ADDED";
export const SHOW_ADD_PROMO_IMG_MODAL = "SHOW_ADD_PROMO_IMG_MODAL";
export const CLOSE_ADD_PROMO_IMG_MODAL = "CLOSE_ADD_PROMO_IMG_MODAL";
export const PROMO_IMG_ADDED = "PROMO_IMG_ADDED";
export const PROMO_IMG_ADDING = "PROMO_IMG_ADDING";
export const PROMO_IMG_DELETED = "PROMO_IMG_DELETED";
export const SHOW_PROMO_EDIT = "SHOW_PROMO_EDIT";
export const CLOSE_PROMO_EDIT = "CLOSE_PROMO_EDIT";
export const PROMO_EDITED = "PROMO_EDITED";
export const PROMO_EDITING = "PROMO_EDITING";
export const PROMO_DELETED = "PROMO_DELETED";
export const EDIT_API_SETTINGS_CLIENT_STATUS = "EDIT_API_SETTINGS_CLIENT_STATUS";
export const EDIT_API_SETTINGS_CRM_STATUS = "EDIT_API_SETTINGS_CRM_STATUS";
export const GET_SIDE_MENU = "GET_SIDE_MENU";
export const EDIT_SIDE_MENU = "EDIT_SIDE_MENU";
export const SAVED_SIDE_MENU = "SAVED_SIDE_MENU";
export const SIDE_MENU_BLOCK_IMG_ADDED = "SIDE_MENU_BLOCK_IMG_ADDED";
export const SIDE_MENU_BLOCK_IMG_ADDING = "SIDE_MENU_BLOCK_IMG_ADDING";
export const TAB_LOGO_IMG_ADDED = "TAB_LOGO_IMG_ADDED";
export const TAB_LOGO_IMG_ADDING = "TAB_LOGO_IMG_ADDING";

//Курьеры
export const GET_COURIER_LIST = "GET_COURIER_LIST";
export const GET_COURIER_ORDER_LIST = "GET_COURIER_ORDER_LIST";
export const GET_COURIERS_ORDERS = "GET_COURIERS_ORDERS";
export const SET_ORDER_DELIVERED = "SET_ORDER_DELIVERED";
export const UPDATE_COURIER_ORDER = "UPDATE_COURIER_ORDER";

//Статьи
export const GET_ARTICLE_LIST = "GET_ARTICLE_LIST";
export const LOADING_ARTICLE = "LOADING_ARTICLE";
export const GET_ARTICLE_ERRORS = "GET_ARTICLE_ERRORS";
export const SHOW_ADD_ARTICLE_MODAL = "SHOW_ADD_ARTICLE_MODAL";
export const CLOSE_ADD_ARTICLE_MODAL = "CLOSE_ADD_ARTICLE_MODAL";
export const ARTICLE_ADDED = "ARTICLE_ADDED";
export const ADDING_ARTICLE = "ADDING_ARTICLE";
export const ARTICLE_EDITING = "ARTICLE_EDITING";
export const ARTICLE_EDITED = "ARTICLE_EDITED";
export const CLOSE_EDIT_ARTICLE_MODAL = "CLOSE_EDIT_ARTICLE_MODAL";
export const SHOW_EDIT_ARTICLE_MODAL = "SHOW_EDIT_ARTICLE_MODAL";
export const ARTICLE_DELETED = "ARTICLE_DELETED";

//SEO Pages
export const GET_SEO_PAGES_LIST = "GET_SEO_PAGES_LIST";
export const LOADING_SEO_PAGES = "LOADING_SEO_PAGES";
export const GET_SEO_PAGES_ERRORS = "GET_SEO_PAGES_ERRORS";
export const SHOW_ADD_SEO_PAGES_MODAL = "SHOW_ADD_SEO_PAGES_MODAL";
export const CLOSE_ADD_SEO_PAGES_MODAL = "CLOSE_ADD_SEO_PAGES_MODAL";
export const SEO_PAGES_ADDED = "SEO_PAGES_ADDED";
export const ADDING_SEO_PAGES = "ADDING_SEO_PAGES";
export const SEO_PAGES_EDITING = "SEO_PAGES_EDITING";
export const SEO_PAGES_EDITED = "SEO_PAGES_EDITED";
export const CLOSE_EDIT_SEO_PAGES_MODAL = "CLOSE_EDIT_SEO_PAGES_MODAL";
export const SHOW_EDIT_SEO_PAGES_MODAL = "SHOW_EDIT_SEO_PAGES_MODAL";
export const SEO_PAGES_DELETED = "SEO_PAGES_DELETED";

//Бонусная система
export const GET_BONUS_SETTINGS = "GET_BONUS_SETTINGS";
export const LOADING_BONUS = "LOADING_BONUS";
export const GET_BONUS_ERRORS = "GET_BONUS_ERRORS";
export const EDIT_BONUS_SETTINGS = "EDIT_BONUS_SETTINGS";
export const SAVED_BONUS_SETTINGS = "SAVED_BONUS_SETTINGS";
export const SAVED_BONUS_SETTINGS_ERRORS = "SAVED_BONUS_SETTINGS_ERRORS";

//Статичные страницы сайта
export const GET_WEB_STATICPAGES = "GET_WEB_STATICPAGES";
export const LOADING_WEB_STATICPAGES = "LOADING_WEB_STATICPAGES";
export const EDIT_WEB_STATICPAGES = "EDIT_WEB_STATICPAGES";
export const SAVED_WEB_STATICPAGES = "SAVED_WEB_STATICPAGES";
export const SAVING_WEB_STATICPAGES = "SAVING_WEB_STATICPAGES";
export const POPULATED_CONTENT_WEB_STATICPAGES = "POPULATED_CONTENT_WEB_STATICPAGES";

//Настройки сайта
export const GET_WEB_SETTINGS = "GET_WEB_SETTINGS";
export const LOADING_WEB_SETTINGS = "LOADING_WEB_SETTINGS";
export const EDIT_WEB_SETTINGS = "EDIT_WEB_SETTINGS";
export const SAVED_WEB_SETTINGS = "SAVED_WEB_SETTINGS";
export const SHOW_ADD_BANNER_WEB_SETTINGS = "SHOW_ADD_BANNER_WEB_SETTINGS";
export const CLOSE_ADD_BANNER_WEB_SETTINGS = "CLOSE_ADD_BANNER_WEB_SETTINGS";
export const BANNER_ADDING_WEB_SETTINGS = "BANNER_ADDING_WEB_SETTINGS";
export const BANNER_ADDED_WEB_SETTINGS = "BANNER_ADDED_WEB_SETTINGS";
export const BANNER_DELETED_WEB_SETTINGS = "BANNER_DELETED_WEB_SETTINGS";
export const SHOW_ADD_BANNER_IMG_MODAL_WEB_SETTINGS = "SHOW_ADD_BANNER_IMG_MODAL_WEB_SETTINGS";
export const CLOSE_ADD_BANNER_IMG_MODAL_WEB_SETTINGS = "CLOSE_ADD_BANNER_IMG_MODAL_WEB_SETTINGS";
export const BANNER_IMG_ADDING_WEB_SETTINGS = "BANNER_IMG_ADDING_WEB_SETTINGS";
export const BANNER_IMG_ADDED_WEB_SETTINGS = "BANNER_IMG_ADDED_WEB_SETTINGS";
export const BANNER_IMG_DELETED_WEB_SETTINGS = "BANNER_IMG_DELETED_WEB_SETTINGS";
export const SHOW_BANNER_EDIT_WEB_SETTINGS = "SHOW_BANNER_EDIT_WEB_SETTINGS";
export const CLOSE_BANNER_EDIT_WEB_SETTINGS = "CLOSE_BANNER_EDIT_WEB_SETTINGS";
export const BANNER_EDITED_WEB_SETTINGS = "BANNER_EDITED_WEB_SETTINGS";
export const BANNER_EDITING_WEB_SETTINGS = "BANNER_EDITING_WEB_SETTINGS";

//Фильтры товаров
export const GET_ITEMFILTER_LIST = "GET_ITEMFILTER_LIST";
export const LOADING_ITEMFILTER = "LOADING_ITEMFILTER";
export const GET_ITEMFILTER_ERRORS = "GET_ITEMFILTER_ERRORS";
export const ITEMFILTER_EDITING = "ITEMFILTER_EDITING";
export const SHOW_ADD_ITEMFILTER_MODAL = "SHOW_ADD_ITEMFILTER_MODAL";
export const CLOSE_ADD_ITEMFILTER_MODAL = "CLOSE_ADD_ITEMFILTER_MODAL";
export const ITEMFILTER_ADDED = "ITEMFILTER_ADDED";
export const ITEMFILTER_ADDING = "ITEMFILTER_ADDING";
export const ITEMFILTER_DELETED = "ITEMFILTER_DELETED";
export const SHOW_EDIT_ITEMFILTER_MODAL = "SHOW_EDIT_ITEMFILTER_MODAL";
export const CLOSE_EDIT_ITEMFILTER_MODAL = "CLOSE_EDIT_ITEMFILTER_MODAL";
export const ITEMFILTER_EDITED = "ITEMFILTER_EDITED";

//Новости
export const GET_NEWS_LIST = "GET_NEWS_LIST";
export const LOADING_NEWS = "LOADING_NEWS";
export const GET_NEWS_ERRORS = "GET_NEWS_ERRORS";
export const NEWS_EDITING = "NEWS_EDITING";
export const SHOW_ADD_NEWS_MODAL = "SHOW_ADD_NEWS_MODAL";
export const CLOSE_ADD_NEWS_MODAL = "CLOSE_ADD_NEWS_MODAL";
export const SHOW_EDIT_NEWS_MODAL = "SHOW_EDIT_NEWS_MODAL";
export const CLOSE_EDIT_NEWS_MODAL = "CLOSE_EDIT_NEWS_MODAL";
export const NEWS_EDITED = "NEWS_EDITED";
export const NEWS_ADDED = "NEWS_ADDED";
export const NEWS_ADDING = "NEWS_ADDING";
export const NEWS_DELETED = "NEWS_DELETED";
export const SHOW_ADD_IMG_NEWS_MODAL = "SHOW_ADD_IMG_NEWS_MODAL";
export const CLOSE_ADD_IMG_NEWS_MODAL = "CLOSE_ADD_IMG_NEWS_MODAL";
export const IMG_NEWS_BTN_DISABLE = "IMG_NEWS_BTN_DISABLE";
export const NEWS_IMG_DELETED = "NEWS_IMG_DELETED";
export const NEWS_IMG_ADDED = "NEWS_IMG_ADDED";

//Акции
export const GET_PROMOTION_LIST = "GET_PROMOTION_LIST";
export const LOADING_PROMOTION = "LOADING_PROMOTION";
export const GET_PROMOTION_ERRORS = "GET_PROMOTION_ERRORS";
export const PROMOTION_EDITING = "PROMOTION_EDITING";
export const SHOW_ADD_PROMOTION_MODAL = "SHOW_ADD_PROMOTION_MODAL";
export const CLOSE_ADD_PROMOTION_MODAL = "CLOSE_ADD_PROMOTION_MODAL";
export const SHOW_EDIT_PROMOTION_MODAL = "SHOW_EDIT_PROMOTION_MODAL";
export const CLOSE_EDIT_PROMOTION_MODAL = "CLOSE_EDIT_PROMOTION_MODAL";
export const PROMOTION_EDITED = "PROMOTION_EDITED";
export const PROMOTION_ADDED = "PROMOTION_ADDED";
export const PROMOTION_ADDING = "PROMOTION_ADDING";
export const PROMOTION_DELETED = "PROMOTION_DELETED";
export const SHOW_ADD_IMG_PROMOTION_MODAL = "SHOW_ADD_IMG_PROMOTION_MODAL";
export const CLOSE_ADD_IMG_PROMOTION_MODAL = "CLOSE_ADD_IMG_PROMOTION_MODAL";
export const IMG_PROMOTION_BTN_DISABLE = "IMG_PROMOTION_BTN_DISABLE";
export const PROMOTION_IMG_DELETED = "PROMOTION_IMG_DELETED";
export const PROMOTION_IMG_ADDED = "PROMOTION_IMG_ADDED";

//Пользователи
export const GET_USERS_LIST = "GET_USERS_LIST";
export const LOADING_USERS = "LOADING_USERS";
export const GET_USERS_ERRORS = "GET_USERS_ERRORS";
export const SHOW_ADD_USER_MODAL = "SHOW_ADD_USER_MODAL";
export const CLOSE_ADD_USER_MODAL = "CLOSE_ADD_USER_MODAL";
export const USER_ADDED = "USER_ADDED";
export const ADDING_USER = "ADDING_USER";
export const SHOW_EDIT_USER_MODAL = "SHOW_EDIT_USER_MODAL";
export const CLOSE_EDIT_USER_MODAL = "CLOSE_EDIT_USER_MODAL";
export const USER_DELETED = "USER_DELETED";
export const USER_EDITED = "USER_EDITED";
export const USER_EDITING = "USER_EDITING";


//Резервирование
export const RESERVE_LIST_LOADED = "RESERVE_LIST_LOADED";
export const RESERVE_LIST_LOADING = "RESERVE_LIST_LOADING";
export const GET_RESERVE_ERRORS = "GET_RESERVE_ERRORS";
export const NEW_RESERVE_NEED_TO_BE_LOAD = "NEW_RESERVE_NEED_TO_BE_LOAD";
export const GET_NEW_RESERVE = "GET_NEW_RESERVE";
export const RESERVE_EDITED = "RESERVE_EDITED";

//Обратная связь
export const FEEDBACK_LIST_LOADED = "FEEDBACK_LIST_LOADED";
export const FEEDBACK_LIST_LOADING = "FEEDBACK_LIST_LOADING";
export const FEEDBACK_LIST_DELETED = "FEEDBACK_LIST_DELETED";
export const GET_FEEDBACK_ERRORS = "GET_FEEDBACK_ERRORS";
export const FEEDBACK_EDITED = "FEEDBACK_EDITED";

//Настройки принтера
export const GET_PRINT_SETTINGS = "GET_PRINT_SETTINGS";
export const EDIT_PRINT_SETTINGS = "EDIT_PRINT_SETTINGS";
export const SET_PRINT_SETTINGS = "SET_PRINT_SETTINGS";
export const PRINT_SETTINGS_LOADING = "PRINT_SETTINGS_LOADING";
export const PRINT_SETTINGS_SENDING = "PRINT_SETTINGS_SENDING";

//Настройки email
export const GET_EMAIL_SETTINGS = "GET_EMAIL_SETTINGS";
export const LOADING_EMAIL_SETTINGS = "LOADING_EMAIL_SETTINGS";
export const GET_EMAIL_ERRORS = "GET_EMAIL_ERRORS";
export const EDIT_EMAIL_SETTINGS = "EDIT_EMAIL_SETTINGS";
export const EMAIL_SETTINGS_SAVED = "EMAIL_SETTINGS_SAVED";

//Подарки в корзине
export const GET_CART_GIFTS_LIST = "GET_CART_GIFTS_LIST";
export const LOADING_CART_GIFTS = "LOADING_CART_GIFTS";
export const GET_CART_GIFTS_ERRORS = "GET_CART_GIFTS_ERRORS";
export const SET_CART_GIFT_ADDING = "SET_CART_GIFT_ADDING";
export const CART_GIFT_ADDED = "CART_GIFT_ADDED";
export const CART_GIFT_DELETED = "CART_GIFT_DELETED";
export const CART_GIFT_EDITED = "CART_GIFT_EDITED";
export const SHOW_CART_GIFT_ADD_IMG_MODAL = "SHOW_CART_GIFT_ADD_IMG_MODAL";
export const CLOSE_CART_GIFT_ADD_IMG_MODAL = "CLOSE_CART_GIFT_ADD_IMG_MODAL";
export const CART_GIFT_IMG_ADDED = "CART_GIFT_IMG_ADDED";
export const CART_GIFT_IMG_DELETED = "CART_GIFT_IMG_DELETED";

//Сервисы по доставке
export const GET_DELIVERY_SERVICES_LIST = "GET_DELIVERY_SERVICES_LIST";
export const LOADING_DELIVERY_SERVICES = "LOADING_DELIVERY_SERVICES";
export const GET_DELIVERY_SERVICES_ERRORS = "GET_DELIVERY_SERVICES_ERRORS";

//PUSHES
export const REGISTERED_PUSH_SENDED = "REGISTERED_PUSH_SENDED";
export const ANONYMOUS_PUSH_SENDED = "ANONYMOUS_PUSH_SENDED";
export const PUSH_MESSAGES_SENDING = "PUSH_MESSAGES_SENDING";

//КУПОНЫ
export const GET_COUPONS_LIST = "GET_COUPONS_LIST";
export const LOADING_COUPONS = "LOADING_COUPONS";
export const GET_COUPONS_ERRORS = "GET_COUPONS_ERRORS";
export const SET_COUPONS_ADDING = "SET_COUPONS_ADDING";
export const COUPONS_ADDED = "COUPONS_ADDED";
export const COUPONS_DELETED = "COUPONS_DELETED";
export const COUPONS_EDITED_ENABLED = "COUPONS_EDITED_ENABLED";

export const COUPON_REPORT_LOADING = "COUPON_REPORT_LOADING";
export const COUPON_REPORT_LOADED = "COUPON_REPORT_LOADED";

export const EDITED_COUPON = "EDITED_COUPON";
export const EDITING_COUPON = "EDITING_COUPON";

export const STOP_ADDED = "STOP_ADDED";


//ТАБЕЛЬ
export const TIMESHEET_LOADED = "TIMESHEET_LOADED";
export const TIMESHEET_LOADING = "TIMESHEET_LOADING";
export const GET_TIMESHEET_ERRORS = "GET_TIMESHEET_ERRORS";

export const EMPLOYLIST_LOADED = "EMPLOYLIST_LOADED";
export const EMPLOYLIST_LOADING = "EMPLOYLIST_LOADING";
export const EMPLOYLIST_HIDE = "EMPLOYLIST_HIDE";
export const EMPLOY_SAVED = "EMPLOY_SAVED";

export const MONTH_LOADING = "MONTH_LOADING";
export const MONTH_LOADED = "MONTH_LOADED";

export const ADD_WORKDAY = "ADD_WORKDAY";
export const ADD_PREPAID = "ADD_PREPAID";
export const ADD_PREM = "ADD_PREM";

export const KITERING_TOGGLED = "KITERING_TOGGLED";
export const SHOW_HIDE_KITERING = "SHOW_HIDE_KITERING";



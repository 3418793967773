// const socketIp = 'http://127.0.0.1:8500/';
//Elita
// const socketIp = 'https://socket.12.hungrygator.ru';
//Pirogi
const socketIp = 'https://185.185.68.194:8500/';
//Zakazplova
// const socketIp = 'https://socket.11.hungrygator.ru';
//Slawa
// const socketIp = 'https://socket.13.hungrygator.ru';
//Vibes
//const socketIp = 'https://socket.14.hungrygator.ru';
//Sumo
//const socketIp = 'https://socket.15.hungrygator.ru';

//Fresh
// const socketIp = 'https://socket.17.hungrygator.ru';

//Pirogovich
// const socketIp = 'https://socket.17.hungrygator.ru';

export default socketIp;
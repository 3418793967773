import {
    REGISTERED_PUSH_SENDED,
    ANONYMOUS_PUSH_SENDED,
    PUSH_MESSAGES_SENDING,
  } from "../actions/types";
  
  const initialState = {
    sending: false,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case REGISTERED_PUSH_SENDED:
        return {
          ...state,
          sending: false,
        };
      case ANONYMOUS_PUSH_SENDED:
        return {
            ...state,
            sending: false,
          };
    case PUSH_MESSAGES_SENDING:
        return{
            ...state,
            sending: true,
        }
      default:
        return state;
    }
  }
  